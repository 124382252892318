var name = 'GenPage';

page[name] = class PageClassName extends page.PageClass{





    // init

    init(ajaxBool = false){

        super.init();



        // elements

        this.image = document.querySelector(".genPage__image");
        this.doubleImage = document.querySelector(".doublePage__image");
        this.address = document.querySelector(".page__address");
        this.header = document.querySelector(".genPage__header");



    }





    // show

    show(ajaxBool = false){

        super.show();

        if(this.image !== null){
            this.image.classList.add("show");
        }

        if(this.doubleImage !== null){
            this.doubleImage.classList.add("show");
        }

        if(this.address !== null){
            this.address.classList.add("show");
        }

        if(this.header !== null){
            this.header.classList.add("show");
        }

        setTimeout(() => {

            page.logo.classList.add("show");
            page.menuButton.classList.add("show");

            page.helpers.showBorder(".doublePage__content .page__border");

        }, 250);

        return true;

    }





};

name = 'genPage';
page.names.push(name);

page[name] = new page['GenPage'];
page[name] = page[name].create();